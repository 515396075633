<template>
  <div class="sampleadd">
    <a-form :form="form" @submit="handleSubmit">
      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="样本名称"
      >
        <a-input
            v-decorator="[
            'name',
            { rules: [{ required: true, message: '样本名称必填' }] },
          ]"
            placeholder="样本名称"
        />
      </a-form-item>
      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="检测方式"
      >
        <a-input
            disabled
            v-decorator="[
            'mode',
            {
              rules: [{ required: true, message: '不能为空' }],
              initialValue: '人工录入',
            },
          ]"
            placeholder="检测方式方式"
        />
      </a-form-item>
      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="重量"
      >
        <a-input
            v-decorator="[
            'count',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
            placeholder="重量, 例如: 10 kg"
        />
      </a-form-item>
      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="被检单位"
      >
        <a-select
            v-decorator="[
            'orign',
            {
              rules: [{ required: true, message: '不能为空' }],
            },
          ]"
            placeholder="选择被检单位"
            @change="handleSelectChange"
        >
          <a-select-option
              v-for="item in datas"
              :key="item.id"
              :value="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <!-- <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="样本来源地址"
      >
        <a-input placeholder="地址" v-decorator="['address']" />
      </a-form-item> -->

      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="接收样本人员"
      >
        <a-input
            v-decorator="[
            'receiver',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
            placeholder="接收样本人员"
        />
      </a-form-item>

      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="接收样本时间"
      >
        <a-date-picker
            show-time
            v-decorator="[
            'create_time',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
            placeholder="选择接收样本时间"
            @change="onChange"
            @ok="onOk"
        />
      </a-form-item>

      <a-form-item
          :label-col="formTailLayout.labelCol"
          :wrapper-col="formTailLayout.wrapperCol"
      >
        <a-button block size="large" type="primary" html-type="submit"
        >提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import {addSample} from "@/api/sample";
import {message} from "ant-design-vue";
import axios from "@/utils/axios";

const formItemLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const formTailLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18, offset: 4},
};
export default {
  name: "Site",
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, {name: "dynamic_rule"}),
      axis: [],
      create_time: "",
      datas: [],
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          // 新增样本
          values.create_time = this.create_time;
          addSample(values).then((res) => {
            if (res.code === 200) {
              message.success("样本添加成功");
              this.form.resetFields();
            }
          });
        }
      });
    },
    onChange(value, dateString) {
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
      this.create_time = dateString;
    },
    onOk(value) {
      console.log("onOk: ", value);
    },
    handleSelectChange(v) {
      console.log(v);
    },
  },
  mounted() {
    axios.post("/subject/all").then((res) => {
      if (res.data) {
        this.datas = res.data;
      }
    });
  },
};
</script>

<style scoped>
.simpleadd {
  margin-top: 50px;
}
</style>