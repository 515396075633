var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sampleadd"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"样本名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ required: true, message: '样本名称必填' }] } ]),expression:"[\n          'name',\n          { rules: [{ required: true, message: '样本名称必填' }] },\n        ]"}],attrs:{"placeholder":"样本名称"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"检测方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mode',
          {
            rules: [{ required: true, message: '不能为空' }],
            initialValue: '人工录入',
          } ]),expression:"[\n          'mode',\n          {\n            rules: [{ required: true, message: '不能为空' }],\n            initialValue: '人工录入',\n          },\n        ]"}],attrs:{"disabled":"","placeholder":"检测方式方式"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"重量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'count',
          { rules: [{ required: true, message: '不能为空' }] } ]),expression:"[\n          'count',\n          { rules: [{ required: true, message: '不能为空' }] },\n        ]"}],attrs:{"placeholder":"重量, 例如: 10 kg"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"被检单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'orign',
          {
            rules: [{ required: true, message: '不能为空' }],
          } ]),expression:"[\n          'orign',\n          {\n            rules: [{ required: true, message: '不能为空' }],\n          },\n        ]"}],attrs:{"placeholder":"选择被检单位"},on:{"change":_vm.handleSelectChange}},_vm._l((_vm.datas),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"接收样本人员"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'receiver',
          { rules: [{ required: true, message: '不能为空' }] } ]),expression:"[\n          'receiver',\n          { rules: [{ required: true, message: '不能为空' }] },\n        ]"}],attrs:{"placeholder":"接收样本人员"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"接收样本时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'create_time',
          { rules: [{ required: true, message: '不能为空' }] } ]),expression:"[\n          'create_time',\n          { rules: [{ required: true, message: '不能为空' }] },\n        ]"}],attrs:{"show-time":"","placeholder":"选择接收样本时间"},on:{"change":_vm.onChange,"ok":_vm.onOk}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formTailLayout.labelCol,"wrapper-col":_vm.formTailLayout.wrapperCol}},[_c('a-button',{attrs:{"block":"","size":"large","type":"primary","html-type":"submit"}},[_vm._v("提交 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }